import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OpenOfficeImage from "assets/images/open_office.jpg";
import MockPlatformImage from "assets/images/analytics_dash_mock.png";
import CircleGraphImage from "assets/images/analytics_mock_circle_graph.png";
import ScoreImage from "assets/images/analytics_mock_score.jpg";
import ValuesImage from "assets/images/analytics_mock_values.jpg";

import LapizImage from "assets/images/lapiz_3d.png";
import PapelImage from "assets/images/papel.png";
import PieImage from "assets/images/pie_graph.png";
import RightArrowImage from "assets/images/arrow_right_gradient.png";
import DownArrowImage from "assets/images/arrow_down_gradient.png";

import "./AnalyticSection.scss";

const AnalyticSection = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div className="w-full px-[30px] sm:px-[60px] xl:px-[0px] mt-[20px] xl:mt-[50px]">
      <div className="middleText mt-[40px] xl:mt-[80px] text-center">
        Analizamos la salud financiera de tu negocio{" "}
        <span className="greenText">sin costo alguno</span>
      </div>

      <div className="relative w-full mt-[50px] xl:mt-[60px] flex justify-center">
        <div className="benefitText">
          Abre tu cuenta en Ave y solicita el análisis financiero que
          contribuirá al desarrollo continuo de tu negocio.
        </div>
      </div>

      <div className="w-full mt-[70px] mb-[50px] hidden sm:flex justify-center">
        <div className="flex flex-col justify-center items-center">
          <img
            src={LapizImage}
            alt=""
            className="w-[40px] h-[60px] xl:w-[50px] xl:h-[84px] object-cover"
          />
          <div className="benefitTitle mt-[25px]">Regístrate</div>
          <div className="benefitDesc mt-[20px]">
            Y completa nuestro requerimiento de informatción.
          </div>
        </div>
        <div className="xl:ml-[50px] mt-[60px]">
          <img
            src={RightArrowImage}
            alt=""
            className="w-[150px] h-[10px] xl:h-[12px] object-cover object-right"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <img
            src={PapelImage}
            alt=""
            className="w-[55px] h-[60px] xl:w-[72px] xl:h-[84px] object-cover"
          />
          <div className="benefitTitle mt-[25px]">Descarga</div>
          <div className="benefitDesc mt-[20px]">
            El análisis que te proporcionará una visión completa de la salud
            financiera de tu negocio.
          </div>
        </div>
        <div className="xl:mr-[50px] mt-[60px] flex justify-end">
          <img
            src={RightArrowImage}
            alt=""
            className="w-[150px] h-[10px] xl:h-[12px] object-cover object-right"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <img
            src={PieImage}
            alt=""
            className="w-[60px] h-[60px] xl:w-[84px] xl:h-[84px] object-cover"
          />
          <div className="benefitTitle mt-[25px]">Disfruta</div>
          <div className="benefitDesc mt-[20px]">
            De la herramienta que te permitirá tomar decisiones informadas y un
            crecimiento sostenible.
          </div>
        </div>
      </div>

      <div className="sm:hidden w-full flex flex-col items-center mt-[40px]">
        <img
          src={LapizImage}
          alt=""
          className="w-[50px] h-[84px] sm:w-[30px] sm:h-[50px] xl:w-[50px] xl:h-[84px] object-cover"
        />
        <div className="benefitTitle mt-[20px]">Regístrate</div>
        <div className="benefitDesc mt-[20px]">
          Completa nuestros requerimientos y abre tu cuenta en menos de un
          minuto.
        </div>

        <div className="py-[15px]">
          <img
            src={DownArrowImage}
            alt=""
            className="w-[14px] h-[44px] object-contain"
          />
        </div>

        <img
          src={PapelImage}
          alt=""
          className="w-[72px] h-[84px] sm:w-[45px] sm:h-[50px] xl:w-[72px] xl:h-[84px] object-cover"
        />
        <div className="benefitTitle mt-[20px]">Descarga</div>
        <div className="benefitDesc mt-[20px]">
          El análisis que te proporcionará una visión completa de la salud
          financiera de tu negocio.
        </div>

        <div className="py-[15px]">
          <img
            src={DownArrowImage}
            alt=""
            className="w-[14px] h-[44px] object-contain"
          />
        </div>

        <img
          src={PieImage}
          alt=""
          className="w-[84px] h-[84px] sm:w-[50px] sm:h-[50px] xl:w-[84px] xl:h-[84px] object-cover"
        />
        <div className="benefitTitle mt-[25px]">Disfruta</div>
        <div className="benefitDesc mt-[20px]">
          Completa nuestros requerimientos y abre tu cuenta en menos de un
          minuto.
        </div>
      </div>

      <div className="w-full justify-center hidden xl:flex">
        <div className="xl:w-[1040px] relative w-full mt-[55px] xl:h-[700px] h-[320px]">
          <div className="absolute openOfficeImage left-[100px] top-[0px]">
            <img src={OpenOfficeImage} alt="" />
          </div>
          <div className="absolute openOfficeImage left-[155px] top-[50px]">
            <img src={MockPlatformImage} alt="" />
          </div>

          <div className="flex absolute circleGraphImage right-[0px] top-[-50px]">
            <img
              src={CircleGraphImage}
              alt=""
              className="w-[480px] h-[220px]"
            />
          </div>

          <div className="flex items-center justify-center absolute scoreImage left-[80px] top-[500px]">
            <img src={ScoreImage} alt="" className="w-[330px] h-[150px]" />
          </div>

          <div className="flex items-center justify-center absolute valueImage right-[80px] top-[530px]">
            <img src={ValuesImage} alt="" className="w-[420px] h-[190px]" />
          </div>
        </div>
      </div>

      <div className="xl:hidden block">
        <div className="w-full flex justify-center mt-[30px] sm:mt-[55px]">
          <div className="relative w-[320px] sm:w-[460px] h-[250px] sm:h-[320px]">
            <div className="absolute openOfficeImage left-[0px] top-[0px]">
              <img src={OpenOfficeImage} alt="" />
            </div>
            <div className="absolute openOfficeImage left-[30px] top-[30px]">
              <img src={MockPlatformImage} alt="" />
            </div>
          </div>
        </div>
        <div className="w-full hidden sm:flex justify-center ml-[50px] mt-[60px]">
          <div className="circleGraphImage">
            <img
              src={CircleGraphImage}
              alt=""
              className="w-[480px] h-[220px]"
            />
          </div>
        </div>

        <div className="w-full flex justify-center sm:ml-[50px] mt-[10px] sm:mt-[20px]">
          <div className="flex justify-center scoreImage">
            <img src={ScoreImage} alt="" className="w-[330px] h-[150px]" />
          </div>
        </div>

        <div className="w-full hidden sm:flex justify-center ml-[50px] mt-[20px]">
          <div className="flex items-center justify-center valueImage">
            <img src={ValuesImage} alt="" className="w-[420px] h-[190px]" />
          </div>
        </div>
      </div>

      <div className="middleText mt-[40px] xl:mt-[130px] text-center">
        ¡Tu éxito no espera, y nosotros estamos aquí para{" "}
        <span className="greenText">acelerarlo juntos</span>!
      </div>

      <div
        className="w-full flex justify-center mt-[50px] mb-[70px]"
        onClick={() => navigate("/register")}
      >
        <div className="startBtn">Abre tu cuenta</div>
      </div>
    </div>
  );
};

export default AnalyticSection;
