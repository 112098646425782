import React from "react";
import { useViewport } from "pages/utils/helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules"; // Lazy removed

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"; // Only import available modules
import "./Carousel.scss";
import image1 from "assets/images/grupo_nama.jpg";
import image2 from "assets/images/casa_mila.jpg";
import image3 from "assets/images/grupo_vog.png";
import image4 from "assets/images/pyco_medical.jpeg";
import image5 from "assets/images/v&l_pharma.jpeg";
import image6 from "assets/images/aic.jpg";
import image7 from "assets/images/tenera.jpg";

const Carousel = () => {
  const viewport = useViewport();
  const baseItems = [
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image1,
    },
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image2,
    },
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image3,
    },
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image4,
    },
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image5,
    },
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image6,
    },
    {
      text: "El factoraje otorgado por Ave es crucial para el impulso de nuestro negocio y su constante crecimiento. La rapidez y flexibilidad del proceso nos permitió tener un acceso a liquidez inmediato, evitando trámites burocráticos innecesarios.",
      name: "Grupo Nama",
      industry: "Hospitalidad",
      image: image7,
    },
  ];

  const viewportConfig = {
    xs: { slidesPerView: 1, slidesPerGroup: 1 },
    sm: { slidesPerView: 1, slidesPerGroup: 1 },
    md: { slidesPerView: 2, slidesPerGroup: 2 },
    lg: { slidesPerView: 2, slidesPerGroup: 2 },
    xl: { slidesPerView: 3, slidesPerGroup: 3 },
    "2xl": { slidesPerView: 4, slidesPerGroup: 4 },
  };

  const { slidesPerView, slidesPerGroup } = viewportConfig[viewport] || { slidesPerView: 1, slidesPerGroup: 1 };

  const items = [
    ...baseItems,
    ...baseItems.slice(0, (slidesPerGroup - (baseItems.length % slidesPerGroup)) % slidesPerGroup),
  ];

  return (
    <div className="mt-[85px]">
      <div className="carouselHeader mb-10">
        <strong>Casos de éxito Ave</strong>
      </div>
      <div className="centered-swiper" id="heroSwiperMobile">
        <Swiper
          navigation={true}
          slidesPerView={slidesPerView}
          slidesPerGroup={slidesPerGroup}
          spaceBetween={10}
          modules={[Autoplay, Navigation]} // Lazy removed from modules
          className={"heroSwiperMobile"}
          loop
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="w-[300px] h-auto pl-[80px] ml-8">
                <div className="bgContainer w-[300px] h-[280px] rounded-2xl p-5">
                  <div className="w-full h-5/6">
                    <p className="carouselText">{item.text}</p>
                  </div>
                </div>
                <div className="flex flex-row w-full h-1/6 justify-around -ml-[70px] -mt-[60px]">
                  <div className="w-32 h-32 border-2 border-sky-500 rounded-full p-1 bg-white overflow-hidden flex items-center justify-center">
                    <img
                      src={item.image}
                      width="130"
                      className="rounded-full"
                      alt={item.name}
                    />
                  </div>
                  <div className="flex flex-col -ml-1 text-sm">
                    <div className="carouselName">{item.name}</div>
                    <div className="carouselIndustry">{item.industry}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Carousel;
