import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useViewport } from "pages/utils/helpers";

// styles
import "./Radio.scss";
import InfoIcon from "assets/images/info-black.svg";

const Radio = (props) => {
  const {
    data,
    selected,
    onChange,
    backgroundColor = "",
    borderRadius = "",
    padding = "",
    gap = 0,
  } = props;
  const viewport = useViewport();

  return (
    <div className="flex flex-col sm:flex-row gap-[10px] sm:gap-[50px]">
      {data.map((item, index) => (
        <div
          key={index}
          className={`flex items-center gap-4 z-[${1000 - index}] pb-[4px]`}
          style={{
            backgroundColor: backgroundColor,
            borderRadius: borderRadius,
            padding: padding,
          }}
        >
          <input
            className="radio"
            style={{ opacity: item.disabled ? 0.5 : 1 }}
            type="radio"
            id={item.id}
            checked={selected.id === item.id}
            onChange={() => onChange(item)}
            disabled={item.disabled !== undefined ? item.disabled : false}
          />

          <label
            className={`label z-10 ${selected.id === item.id ? "selected" : ""}`}
            htmlFor={item.id}
            onClick={() =>
              item.disabled !== undefined && item.disabled ? {} : onChange(item)
            }
            style={{ opacity: item.disabled ? 0.5 : 1 }}
          >
            {item.label}
          </label>

          <div className="tooltip" data-tooltip-id={`check-${index}`}>
            <img src={InfoIcon} width="16" alt="" />
          </div>
          <ReactTooltip
            id={`check-${index}`}
            place="bottom"
            style={{
              width: viewport === "xs" ? "300px" : "400px",
              textAlign: "start",
            }}
            content={item.info}
          />
        </div>
      ))}
    </div>
  );
};

export default Radio;
